// mutations.js
export default {
  SET_CURRENT_LESSON_OPENED(state, currentModuleOpened) {
    state.currentModuleOpened = currentModuleOpened || null;
  },
  SET_PARAMS(state, params) {
    state.params = params;
  },
  SET_COURSE(state, course) {
    state.course = course;
  },
  SET_COURSE_PERCENTAGE(state, percentage) {
    state.coursePercentage = percentage;
  },
  SET_MODULES(state, modules) {
    state.modules = modules;
  },
  SET_CURRENT_MODULE_LESSONS(state, lessons) {
    state.currentModuleLessons = lessons;
  },
  SET_CURRENT_LESSON(state, lesson) {
    state.currentLesson = lesson;
  },
  SET_PREV_LESSON(state, prev) {
    state.prevLesson = prev;
  },
  SET_NEXT_LESSON(state, next) {
    state.nextLesson = next;
  },
  SET_LOADING(state, loading) {
    state.loading = Object.assign(state.loading, loading);
  },
  SET_SUBMITING(state, submitting) {
    state.submitting = Object.assign(state.submitting, submitting);
  },
  SET_LESSON_COMMENTS(state, comments) {
    state.lessonComments = comments;
  },
  CLEAR(state) {
    Object.assign(state, {
      params: {},
      course: {
        title: "",
      },
      modules: [],
      currentModuleOpened: null,
      coursePercentage: 0,
      currentLesson: {},
      currentModuleLessons: [],
      currentLessonComments: [],
      lessonComments: [],
      prevLesson: null,
      nextLesson: null,
      loading: {
        lesson: false,
        module: false,
        course: false,
        comments: false
      },
      submitting: {
        completion: false,
        comment: false,
      }
    })
  }
};
