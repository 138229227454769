// export default {
//   namespaced: true,
//   state: {
//     saldo: 0,
//     saldoBlock:0
//   },
//   mutations: {
//     UPDATE_SALDO(state, payload) {
//       state.saldo = payload
//     },
//     UPDATE_SALDO_BLOCK(state, payload) {
//       state.saldoBlock = payload
//     },
//   },
 
// }

// created(){
//     this.$store.dispatch('acao/puxarAcao')
//   }
const state = {
    show: false,
};

const mutations = {
  UPDATE_MODAL(state, payload) {
    state.show = payload
  },
};

export {
  state,
  mutations,
}