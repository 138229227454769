<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1L11.8967 0.557376C11.7282 0.216072 11.3806 0 11 0C10.6194 0 10.2718 0.216072 10.1033 0.557376L11 1ZM14.09 7.26L13.1933 7.70262C13.3388 7.99747 13.62 8.20193 13.9454 8.24949L14.09 7.26ZM21 8.27L21.6977 8.98636C21.9704 8.72083 22.0686 8.32358 21.9512 7.96157C21.8339 7.59956 21.5212 7.33555 21.1446 7.28051L21 8.27ZM16 13.14L15.3023 12.4236C15.0664 12.6534 14.9587 12.9845 15.0144 13.309L16 13.14ZM17.18 20.02L16.7145 20.9051C17.0515 21.0823 17.4598 21.0528 17.7677 20.8291C18.0757 20.6053 18.23 20.2261 18.1656 19.851L17.18 20.02ZM11 16.77L11.4655 15.8849C11.1741 15.7317 10.8259 15.7317 10.5345 15.8849L11 16.77ZM4.82 20.02L3.83439 19.851C3.77004 20.2261 3.92429 20.6053 4.23227 20.8291C4.54024 21.0528 4.94854 21.0823 5.28545 20.9051L4.82 20.02ZM6 13.14L6.98561 13.309C7.04127 12.9845 6.93361 12.6534 6.69773 12.4236L6 13.14ZM1 8.27L0.855372 7.28051C0.478812 7.33555 0.166132 7.59956 0.0487539 7.96157C-0.0686238 8.32358 0.029649 8.72083 0.302267 8.98636L1 8.27ZM7.91 7.26L8.05463 8.24949C8.37998 8.20193 8.66117 7.99747 8.80671 7.70262L7.91 7.26ZM10.1033 1.44262L13.1933 7.70262L14.9867 6.81738L11.8967 0.557376L10.1033 1.44262ZM13.9454 8.24949L20.8554 9.25949L21.1446 7.28051L14.2346 6.27051L13.9454 8.24949ZM20.3023 7.55364L15.3023 12.4236L16.6977 13.8564L21.6977 8.98636L20.3023 7.55364ZM15.0144 13.309L16.1944 20.189L18.1656 19.851L16.9856 12.971L15.0144 13.309ZM17.6455 19.1349L11.4655 15.8849L10.5345 17.6551L16.7145 20.9051L17.6455 19.1349ZM10.5345 15.8849L4.35455 19.1349L5.28545 20.9051L11.4655 17.6551L10.5345 15.8849ZM5.80561 20.189L6.98561 13.309L5.01439 12.971L3.83439 19.851L5.80561 20.189ZM6.69773 12.4236L1.69773 7.55364L0.302267 8.98636L5.30227 13.8564L6.69773 12.4236ZM1.14463 9.25949L8.05463 8.24949L7.76537 6.27051L0.855372 7.28051L1.14463 9.25949ZM8.80671 7.70262L11.8967 1.44262L10.1033 0.557376L7.01329 6.81738L8.80671 7.70262Z" :fill="setColor()"/>
    </svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
  }
}
</script>