const context = require.context('.', false, /(The|Base)[\w-]+\.vue$/)

const components = {}

context.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const componentConfig = context(fileName)
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

  components[componentName] = componentConfig.default || componentConfig
})

export default components
