import Api from '@/services/API';


/**
 * @typedef {AuthService}
 */

export default class Auth extends Api {

    /**
     * @type {String}
     */
    static base = process.env.VUE_APP_API_HOST
        // testespark.gpages.com.br


    /**
     * @type {String}
     */
    static resource = '/member/login'

    /**
     * @param {String} resource
     * @param {Object} options
     * @param {Object} http
     */
    constructor(resource, options = {}, http = null) {
        super(Auth.normalize(Auth.base, resource), options, http);
    }

    /**
     * @return {this}
     */
    static build() {
        return new this(this.resource);
    }

    login(username, password, captcha) {

        let data = {
            email: username,
            password: password,
            captcha: captcha,
        }

        return this.post('', data);
    }

    logout() {
        return this.post();
    }
}