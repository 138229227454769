/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
});

// eslint-disable-next-line no-restricted-syntax
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
