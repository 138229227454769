import Rest from '@/services/Rest';

/**
 * @typedef {MemberService}
 */
export default class MemberService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'member'
}