import Rest from '@/services/Rest';

/**
 * @typedef {ClassService}
 */
export default class ClassService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'course-class'
}