<template>
  <Multiselect 
    :class="[
      'play-select',
      { 'is-invalid': state === false }
    ]"
    :name="name" 
    v-model="selected"
    :searchable="searchable"
    :placeholder="placeholder || $t('select')" 
    :options="options" 
    :v-validate="vValidate" 
    :state="state" 
    :showLabels="false"
    :label="label" 
    :track-by="trackBy"
    :open-direction="openDirection"
  />
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    value: {},
    label: {
      type: String,
      default: "label"
    },
    openDirection: {
      type: String
    },
    placeholder: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    trackBy: {
      type: String,
      default: "id"
    },
    vValidate: {},
    state: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected: {
      get() {
        return this.options.find(it => it[this.trackBy] == this.value);
      },
      set(value) {
        this.$emit("input", value[this.trackBy]);
      }
    }
  }
}
</script>
<style lang="scss">

.play-select.is-invalid > .multiselect__tags {
  border: 1px solid var(--dangercolor) !important;
}

.play-select {

  label {
    font-size: 16px;
    font-weight: 600;
    color: var(--fontcolor);
  }

  .toggle-select-all {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--multiselect-toggle-btn-color);
    text-decoration: underline;
    font-size: 12px;
    margin: 0;
  }

  .multiselect {
    &__single {
      margin: 0;
      color: var(--input-color);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
    }

    &__tags {
      padding: 12px !important;
      background-color: var(--input-background) !important;
      border: 1px solid var(--input-border) !important;
      border-radius: 8px !important;
      overflow: auto;
      max-height: 200px;
      height: auto !important;
    }

    &__strong {
      margin: 0;
      color: white !important;
      background-color: #2a63ab !important;
      border: 1px solid white !important;
      border-radius: 1rem;
      font-size: 12px;
      padding: 0 6px;
      margin: 4px 4px 0 0;
    }

    &__placeholder, &__input::placeholder {
      color: var(--input-placeholder) !important;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }

    &__input {
      margin: 0 !important;

      &:focus {
        margin: 4px 0;
      }
    }

    &__tag {
      background-color: var(--multiselect-badge-bg) !important;
      border: 1px solid var(--input-border) !important;
      font-weight: 500;
      border-radius: 1rem;
      font-size: 12px;
      padding: 4px 6px;
      margin: 4px 4px 0 0;

      span {
        margin-right: 1rem;
        background-color: var(--multiselect-badge-bg) !important;
        color: var(--multiselect-badge-color) !important;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon:hover {
        background-color: transparent !important;
      }

      &-icon:after {
        color: var(--multiselect-badge-border) !important;
        content: "×";
        font-size: 0.875rem;
      }
    }

    &__option {
      color: var(--multiselect-item-color) !important;
      font-weight: 400 !important;
      font-size: 14px !important;

      &--selected {
        background-color: var(--multiselect-item-selected) !important;
        color: var(--input-color);
      }

      &--highlight {
        background-color: var(--multiselect-item-hover-bg) !important;
      }
    }
    
    &__select {
      top: 4px !important;
    }

    &__select:before {
      position: relative;
      right: 0;
      top: 20%;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      border-color: var(--input-border);
      content: " ";
    }

    &__content-wrapper {
      background-color: var(--multiselect-bg) !important;
      border: 1px solid var(--input-border) !important;
      border-radius: 0.25rem;
      margin: 0.3rem 0;
    }

    &__content {
      border-color: transparent !important;
    }
  }
}
</style>
