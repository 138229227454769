import Rest from '@/services/Rest';

/**
 * @typedef {MemberMetaService}
 */
export default class MemberMetaService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'member/meta'
}