import Rest from '@/services/Rest';

/**
 * @typedef {MediaService}
 */
export default class MediaService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'media'
}